import React from 'react';
import { createProsumerLearnModeSubscription } from '../hydra';
import { useHistory } from 'react-router-dom';
import * as Analytics from '../analytics';
import { EVENTS } from '../analytics';
import { PaymentCallbackPage } from '../components/SoloOnboarding/Screens/PaymentCallback';
import styled from 'styled-components';
import { trackPixelEvent } from '../facebook';
import { useGetProsumerProfile } from '../hooks/use-hydra';

const PageWrapper = styled.div`
  height: 100vh;
  padding: 0;
  margin: 0;
`;

export default function PaymentCallback() {
  const history = useHistory();

  Analytics.useTrackViewOnMount(EVENTS.PROSUMER.UPSELL.VIEW_PAYMENT_CALLBACK);

  const { retry } = useGetProsumerProfile();
  const createSubscription = React.useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentIntentId = urlParams.get('payment_intent');

    if (!paymentIntentId)
      throw new Error('Payment intent not found in query params');

    const { email, isTrial, currency, value, metaEventId } =
      await createProsumerLearnModeSubscription({
        paymentIntentId,
      });
    await retry();

    if (isTrial) {
      trackPixelEvent(
        'StartTrial',
        {
          currency,
          value,
        },
        metaEventId
      );
    }

    if (email) {
      history.push('/');
    }
  }, [history, retry]);

  React.useEffect(() => {
    createSubscription();
  }, [createSubscription]);

  return (
    <PageWrapper>
      <PaymentCallbackPage />
    </PageWrapper>
  );
}
