import { ActivityContextType } from '..';
import { ActivitySubmission } from '../../../hydra';

const secondsBetweenTimestamps = (start: Date, end: Date) =>
  (end.getTime() - start.getTime()) / 1000;

const getSecondsSpent = (activity: ActivityContextType) =>
  activity.pageFocusTimestampIntervals.reduce(
    (total, [start, end = new Date()]) =>
      total + secondsBetweenTimestamps(start, end),
    0
  );

const parseSubmission = (activity: ActivityContextType): ActivitySubmission => {
  return {
    difficulty_rating: activity.submission.difficultyRating,
    feedback: activity.submission.feedback,
    activity_session_id: activity.mentorReview.sessionId,
    seconds_spent: getSecondsSpent(activity),
    activity_id: activity.id,
    answer_states: Object.fromEntries(
      Object.values(activity.submission.questionSlugToAnswer).map((answer) => [
        answer.questionSlug,
        {
          value: answer.value,
          is_correct: answer.isCorrect,
          is_submitted: answer.isAnswered,
          submitted_at: null,
        },
      ])
    ),
  };
};

export default parseSubmission;
