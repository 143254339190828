import styled from 'styled-components';
import SQLPlayground from '../components/SQLPlayground';
import useSQLRunner from '../components/SQLPlayground/use-sql-runner';
import React, { useCallback, useState } from 'react';
import {
  EmptyResult,
  ErrorResult,
  SQLResult,
} from '../contexts/activity/helpers/is-sql-playground-question-correct';

const PlaygroundContainer = styled.div`
  height: 100vh;
`;

export default function EmbeddableSQLPlayground() {
  const urlParams = new URLSearchParams(window.location.search);
  const customSchemaTemplate = urlParams.get('customSchemaTemplate');
  const initialSQL = urlParams.get('initialSQL') ?? '';
  const isReadOnly = urlParams.get('readonly') === 'true';
  const schemaGistUrl = urlParams.get('schemaGistUrl');
  const [query, setQuery] = useState(initialSQL);
  const [schemaTemplate, setSchemaTemplate] = useState<string>(
    customSchemaTemplate ?? ''
  );
  const SQLRunner = useSQLRunner({
    id: 0,
    setupSQL: schemaTemplate,
  });

  React.useEffect(() => {
    // Pull the setup sql from the schema gist if it exists
    if (schemaGistUrl) {
      fetch('https://gist.githubusercontent.com/' + schemaGistUrl)
        .then((response) => response.text())
        .then((data) => {
          setSchemaTemplate(data);
        });
    }
  });

  const [result, setResult] = useState<SQLResult>({
    type: 'EMPTY',
    value: undefined,
  });

  const onRun = useCallback(
    (query: string) => {
      const runnerOutput = SQLRunner.runQuery(query);
      if (runnerOutput?.type === 'EMPTY') {
        setResult(runnerOutput as EmptyResult);
      } else if (runnerOutput?.type === 'ERROR') {
        setResult(runnerOutput as ErrorResult);
      } else if (runnerOutput?.type === 'QUERY') {
        setResult(runnerOutput as SQLResult);
      }
    },
    [SQLRunner]
  );
  return (
    <PlaygroundContainer>
      <SQLPlayground
        query={query}
        schemas={SQLRunner.schemas}
        onQueryChange={setQuery}
        onRunQuery={onRun}
        onReset={() => {
          setQuery('');
        }}
        isLoading={SQLRunner.isLoading}
        result={result}
        error={SQLRunner.error as unknown as Error}
        isReadOnly={isReadOnly}
      />
    </PlaygroundContainer>
  );
}
