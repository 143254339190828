import React from 'react';

const TranslatableListItem = ({
  className,
  children,
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  ordered,
  ...props
}: {
  className: string;
  children?: React.ReactNode[];
  ordered: boolean;
  [key: string]: string | React.ReactNode;
}) => {
  const cleanedChildren = (children ?? []).map((child, ind) => {
    if (typeof child === 'string') {
      return <span key={ind}>{child}</span>;
    }
    return child;
  });
  return (
    <li {...props} className={className}>
      {cleanedChildren}
    </li>
  );
};

export default TranslatableListItem;
