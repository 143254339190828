import styled, { useTheme } from 'styled-components';
import { NAVBAR_HEIGHT } from '../Navbar';
import React from 'react';
import { ActivityContext, ActivityContextType } from '../../contexts/activity';
import Button from '../elements/Button';
import autoAnimate from '@formkit/auto-animate';
import {
  AIChat,
  AIWorkflow,
  MESSAGE_CONTEXT_TYPES,
} from '../../contexts/ai-workflow';
import { ACTIVITY_STEP } from './Content';
import useSelection from '../../hooks/use-selection';
import { toast } from 'react-toastify';
import { ACTIVITY_PAGE_TYPE, ActivityPageProps } from '.';
import ActivityDiscussModal from './DiscussModal';

import { QUESTION_TYPE } from './Question';

const ActionBarWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.navigation};
  height: ${NAVBAR_HEIGHT}px;
  min-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  overflow-x: auto;
  gap: 32px;
`;

const NavButton = styled(Button)`
  margin-top: 0;
  border-radius: 8px;
  padding: 6px 16px;

  p {
    font-size: 12px;
  }
  i {
    font-size: 14px;
  }
`;

const AIActionButton = styled(Button)`
  margin-top: 0;
  border-radius: 8px;
  padding: 6px 16px;

  p {
    font-size: 12px;
  }
  i {
    font-size: 14px;
  }
`;

const AIActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const getShouldShowHintFromActivity = (activity: ActivityContextType) => {
  if (activity?.currentStep?.type !== ACTIVITY_STEP.QUESTION) {
    return false;
  }

  const question =
    activity.exercise.questions[activity.currentStep.questionIndex];
  if (
    ![QUESTION_TYPE.PYTHON_PLAYGROUND, QUESTION_TYPE.SQL_PLAYGROUND].includes(
      question.type as QUESTION_TYPE
    )
  ) {
    return true;
  }

  const solution =
    activity.submission.questionSlugToAnswer[
      activity.currentStep?.questionSlug
    ];

  const didNotAttemptPython =
    solution.value === question.initial_py ||
    (!solution.value && !question.initial_py);

  const answerIsEmpty = !solution.value;

  return (
    solution.isRan ||
    solution.isRan === null ||
    didNotAttemptPython ||
    answerIsEmpty
  );
};

const getHintCopy = (activity: ActivityContextType, chat: AIChat) => {
  if (
    !activity.currentStep ||
    activity.currentStep.type !== ACTIVITY_STEP.QUESTION
  ) {
    return 'Get a hint';
  }
  const currentQuestionIndex = activity.currentStep.questionIndex;
  const solution =
    activity.submission.questionSlugToAnswer[
      activity.currentStep?.questionSlug
    ];

  const previousHints = chat.messages.filter((message) => {
    const messageContext = message.message_context;
    const isHint =
      messageContext && messageContext.context_type === 'question_hint';

    const isUserQuery = message.is_bot === false;
    if (!isHint || !isUserQuery) {
      return false;
    }

    const isSameQuestionAttempt =
      messageContext.context_content.question_index === currentQuestionIndex &&
      messageContext.context_content.attempted_answer == solution.value;
    return isSameQuestionAttempt;
  });

  if (previousHints.length === 0) {
    return 'Get a hint';
  }

  if (previousHints.length === 1) {
    return 'Get another hint';
  }

  return 'Get answer';
};

const AIActions = ({
  type,
  startDiscussion,
}: {
  type: ActivityPageProps['type'];
  startDiscussion: ActivityPageProps['startDiscussion'];
}) => {
  const [activity] = React.useContext(ActivityContext);
  const {
    setIsChatOpen,
    setMessageContext,
    setPendingAiMessage,
    hasAIAccess,
    chat,
  } = React.useContext(AIWorkflow);
  const { selection, section: selectionSection } = useSelection();

  const parent = React.useRef(null);
  const theme = useTheme();

  const shouldSeeDiscussAction =
    type === ACTIVITY_PAGE_TYPE.TEAMS &&
    activity?.mentorReview?.hasMentor &&
    selection;

  React.useEffect(() => {
    parent.current &&
      autoAnimate(parent.current, {
        duration: 300,
        easing: 'ease-in-out',
      });
  }, [parent]);

  const onSummarize = () =>
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.SUMMARIZE_ACTIVITY,
    });

  const onMotivate = () =>
    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.MOTIVATE_ACTVITY,
    });

  const onAskSelection = () => {
    if (!selection) return;

    setMessageContext({
      context_type: MESSAGE_CONTEXT_TYPES.HIGHLIGHT,
      context_content: {
        section_of_interest: selectionSection ?? '',
        highlighted_text: selection.toString().trim(),
      },
    });
  };

  const onHint = () => {
    if (activity?.currentStep?.type !== ACTIVITY_STEP.QUESTION) {
      return;
    }
    const activeQuestionIndex =
      activity.currentStep?.type === ACTIVITY_STEP.QUESTION
        ? activity.currentStep?.questionIndex
        : null;

    if (activeQuestionIndex === null) {
      return;
    }

    const answer =
      activity.submission.questionSlugToAnswer[
        activity.currentStep?.questionSlug
      ];

    const question = activity.exercise.questions[activeQuestionIndex];

    if (
      [QUESTION_TYPE.PYTHON_PLAYGROUND, QUESTION_TYPE.SQL_PLAYGROUND].includes(
        question.type as QUESTION_TYPE
      )
    ) {
      try {
        setMessageContext({
          context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
          context_content: {
            question_index: activeQuestionIndex,
            attempted_answer: answer.query ?? answer.value ?? '',
            is_correct: answer.isCorrect,
          },
        });
      } catch (e) {
        toast.error(
          'Sorry, your code did not finish running. Please try again.'
        );
      }
    } else {
      setMessageContext({
        context_type: MESSAGE_CONTEXT_TYPES.QUESTION_HINT,
        context_content: {
          question_index: activeQuestionIndex,
          attempted_answer: answer.query ?? answer.value ?? '',
        },
      });
    }
  };

  const onAskQuestion = () => {
    if (activity?.currentStep?.type === ACTIVITY_STEP.QUESTION) {
      const activeQuestionIndex =
        activity.currentStep?.type === ACTIVITY_STEP.QUESTION
          ? activity.currentStep?.questionIndex
          : null;

      if (activeQuestionIndex === null) {
        return;
      }
      const answer =
        activity.submission.questionSlugToAnswer[
          activity.currentStep?.questionSlug
        ];
      setMessageContext({
        context_type: MESSAGE_CONTEXT_TYPES.WORKING_QUESTION,
        context_content: {
          question_index: activeQuestionIndex,
          attempted_answer: answer.value ?? '',
          is_correct: answer.isCorrect,
        },
      });
    } else {
      setMessageContext(null);
    }
    setIsChatOpen(true);
    setPendingAiMessage('What can I help you with?');
  };

  const [textToDiscussWithMentor, setTextToDiscussWithMentor] =
    React.useState('');
  const shouldAllowHint = getShouldShowHintFromActivity(activity);
  const hintCopy = getHintCopy(activity, chat);
  return (
    <>
      {textToDiscussWithMentor && (
        <ActivityDiscussModal
          contentText={textToDiscussWithMentor}
          onStartDiscussion={startDiscussion}
          onClose={() => {
            setTextToDiscussWithMentor('');
          }}
        />
      )}
      <AIActionsWrapper ref={parent}>
        {hasAIAccess && (
          <AIActionButton
            type="secondary"
            color={theme.colors.buttonSecondary}
            label={'Ask a question'}
            faIcon="fa-duotone fa-sparkles"
            showIconBeforeLabel
            onClick={onAskQuestion}
          />
        )}
        {activity?.currentStep?.type === ACTIVITY_STEP.LESSON && hasAIAccess && (
          <>
            <AIActionButton
              type="secondary"
              color={theme.colors.buttonSecondary}
              label={'Summarize Activity'}
              faIcon="fa-duotone fa-sparkles"
              showIconBeforeLabel
              onClick={onSummarize}
            />
            <AIActionButton
              type="secondary"
              color={theme.colors.buttonSecondary}
              label={'Motivate Me'}
              faIcon="fa-duotone fa-sparkles"
              showIconBeforeLabel
              onClick={onMotivate}
            />
          </>
        )}
        {activity?.currentStep?.type === ACTIVITY_STEP.QUESTION &&
          hasAIAccess && (
            <AIActionButton
              type="secondary"
              color={theme.colors.buttonSecondary}
              label={hintCopy}
              isDisabled={!shouldAllowHint}
              faIcon="fa-duotone fa-sparkles"
              showIconBeforeLabel
              onClick={onHint}
              tooltip={shouldAllowHint ? '' : 'Run your code first!'}
            />
          )}
        {selection && hasAIAccess && (
          <AIActionButton
            type="primary"
            color={theme.colors.buttonSecondary}
            label={'What does this mean?'}
            faIcon="fa-duotone fa-sparkles"
            showIconBeforeLabel
            onClick={onAskSelection}
          />
        )}
        {shouldSeeDiscussAction && (
          <AIActionButton
            type="primary"
            color={theme.colors.buttonPrimary}
            label={'Discuss with your Mentor'}
            showIconBeforeLabel
            onClick={() => {
              if (selection) {
                setTextToDiscussWithMentor(selection?.toString().trim());
              }
              setIsChatOpen(false);
            }}
            faIcon="fa-duotone fa-chalkboard-teacher"
          />
        )}
      </AIActionsWrapper>
    </>
  );
};

export default function ActionBar({
  type,
  startDiscussion,
  isSubmitting,
  submitAndMoveToReview,
  submitAndMoveToQuestion,
  submitAndMoveToNextLesson,
  submitAndMoveToLesson,
}: {
  startDiscussion: ActivityPageProps['startDiscussion'];
  type: ActivityPageProps['type'];
  submitAndMoveToReview: () => void;
  submitAndMoveToQuestion: ({
    questionIndex,
    questionSlug,
  }: {
    questionIndex: number;
    questionSlug: string;
  }) => void;
  submitAndMoveToNextLesson: () => void;
  submitAndMoveToLesson: () => void;
  isSubmitting: boolean;
}) {
  return (
    <ActionBarWrapper>
      <AIActions type={type} startDiscussion={startDiscussion} />
      <NavButtons
        submitAndMoveToReview={submitAndMoveToReview}
        submitAndMoveToQuestion={submitAndMoveToQuestion}
        submitAndMoveToNextLesson={submitAndMoveToNextLesson}
        submitAndMoveToLesson={submitAndMoveToLesson}
        isSubmitting={isSubmitting}
      />
    </ActionBarWrapper>
  );
}

const NavButtons = ({
  submitAndMoveToReview,
  submitAndMoveToQuestion,
  submitAndMoveToLesson,
  submitAndMoveToNextLesson,
  isSubmitting,
}: {
  submitAndMoveToReview: () => void;
  submitAndMoveToQuestion: ({
    questionIndex,
    questionSlug,
  }: {
    questionIndex: number;
    questionSlug: string;
  }) => void;
  submitAndMoveToLesson: () => void;
  submitAndMoveToNextLesson: () => void;
  isSubmitting: boolean;
}) => {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const currentStep = activity?.currentStep;
  const theme = useTheme();

  const numQuestions = activity.exercise.questions.length;
  const canGoBack = currentStep?.type !== ACTIVITY_STEP.LESSON;
  const hasAnsweredAllQuestions = activity.exercise.questions.every(
    (question) =>
      activity.submission.questionSlugToAnswer[question.slug].isAnswered
  );
  const hasAIReview = activity.latestSavedSubmission?.ai_review;

  const onBack = () => {
    if (currentStep?.type === ACTIVITY_STEP.FEEDBACK) {
      return submitAndMoveToQuestion({
        questionIndex: numQuestions - 1,
        questionSlug: activity.exercise.questions[numQuestions - 1].slug,
      });
    }

    if (currentStep?.type === ACTIVITY_STEP.QUESTION) {
      if (currentStep.questionIndex === 0) {
        return submitAndMoveToLesson();
      }
      return submitAndMoveToQuestion({
        questionIndex: currentStep.questionIndex - 1,
        questionSlug:
          activity.exercise.questions[currentStep.questionIndex - 1].slug,
      });
    }
  };

  const currentQuestionAnswer =
    currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.submission.questionSlugToAnswer[
          (
            currentStep as {
              type: ACTIVITY_STEP.QUESTION;
              questionSlug: string;
            }
          ).questionSlug
        ]
      : null;

  const shouldConfirmNext =
    currentStep?.type === ACTIVITY_STEP.QUESTION &&
    !currentQuestionAnswer?.isAnswered;

  const onNext = async () => {
    if (currentStep?.type === ACTIVITY_STEP.LESSON) {
      return dispatch({
        type: 'SET_PAGE_STEP',
        payload: {
          step: {
            type: ACTIVITY_STEP.QUESTION,
            questionIndex: 0,
            questionSlug: activity.exercise.questions[0].slug,
          },
        },
      });
    }

    if (
      currentStep?.type === ACTIVITY_STEP.QUESTION &&
      currentStep.questionIndex === numQuestions - 1
    ) {
      if (
        hasAIReview ||
        hasAnsweredAllQuestions ||
        window.confirm(
          'We recommend attempting all exercises before moving to the review. Do you want to continue?'
        )
      ) {
        submitAndMoveToReview();
      }
      return;
    }

    if (currentStep?.type === ACTIVITY_STEP.QUESTION) {
      if (
        currentQuestionAnswer?.isAnswered ||
        window.confirm(
          "You haven't tried this exercise yet. Are you sure you want to move on?"
        )
      ) {
        submitAndMoveToQuestion({
          questionIndex: currentStep.questionIndex + 1,
          questionSlug:
            activity.exercise.questions[currentStep.questionIndex + 1].slug,
        });
      }
      return;
    }

    if (currentStep?.type === ACTIVITY_STEP.FEEDBACK) {
      return submitAndMoveToNextLesson();
    }
  };

  return (
    <AIActionsWrapper>
      <NavButton
        color={theme.colors.textPlaceholder}
        label="Back"
        type="secondary"
        tooltip={canGoBack ? '' : 'You are at the beginning'}
        faIcon="fa-solid fa-arrow-left"
        showIconBeforeLabel
        isDisabled={!canGoBack}
        onClick={onBack}
      />

      <NavButton
        style={{ minWidth: '150px' }}
        isDisabled={isSubmitting}
        tooltip={shouldConfirmNext ? 'Try the exercise first!' : ''}
        label="Next"
        type={'primary'}
        faIcon="fa-solid fa-arrow-right"
        onClick={onNext}
        isLoading={isSubmitting}
      />
    </AIActionsWrapper>
  );
};
