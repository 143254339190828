// import { set } from 'es-cookie';
import { Auth0DecodedHash } from 'auth0-js';
import { trackPixelEvent } from '../../facebook';
import {
  createProsumerLearnModePaymentIntent,
  createProsumerLearnModeSubscription,
  finishSoloOnboarding,
} from '../../hydra';
import { saveSoloOnboarding } from '../../hydra';
import {
  GOALS,
  LEARNING_GOALS_DATA,
  PROFILES,
  TIME_EFFORT,
  TOPICS_DATA,
} from './config';
import { ContextType, OnboardQuestion } from './state-machine';

export enum SERVICES {
  HANDLE_PAYMENT_CALLBACK = 'HANDLE_PAYMENT_CALLBACK',
  HANDLE_AUTH_CALLBACK = 'HANDLE_SIGNUP_CALLBACK',
  INIT_PAYMENT = 'INIT_PAYMENT',
  SAVE_ONBOARDING_ANSWERS = 'SAVE_ONBOARDING_ANSWERS',
}

export const parameterisedServices = {
  [SERVICES.HANDLE_AUTH_CALLBACK]:
    ({
      handleAuthCallback,
      getTokenSilently,
    }: {
      handleAuthCallback: () => Promise<Auth0DecodedHash | null>;
      getTokenSilently: () => Promise<string>;
    }) =>
    async () => {
      await handleAuthCallback();
      const token = await getTokenSilently();
      const result = await finishSoloOnboarding(token);
      return {
        partnerCode: result.partnerSignupCode,
        partnerWelcomeMessage: result.partnerWelcomeMessage,
        hasPartnerCode: result.hasPartnerCode,
      };
    },
};

export default {
  [SERVICES.HANDLE_PAYMENT_CALLBACK]: async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const paymentIntentId = urlParams.get('payment_intent');

    if (!paymentIntentId)
      throw new Error('Setup intent not found in query params');

    const { email, isTrial, currency, value, metaEventId } =
      await createProsumerLearnModeSubscription({
        paymentIntentId,
      });

    if (isTrial) {
      trackPixelEvent(
        'StartTrial',
        {
          currency,
          value,
        },
        metaEventId
      );
    }

    return { email };
  },

  [SERVICES.INIT_PAYMENT]: async (context: ContextType) => {
    // call hydra api to create stripe intent and apply discount code
    if (!context.email) {
      throw new Error('Sorry, we need your email first.');
    }

    const { paymentIntentClientSecret, discountPercentage } =
      await createProsumerLearnModePaymentIntent({
        userEmail: context.email,
        isMonthlySubscription: context.isMonthlySubscription,
      });

    return { paymentIntentClientSecret, discountPercentage };
  },
  [SERVICES.SAVE_ONBOARDING_ANSWERS]: async (context: ContextType) => {
    if (!context.email) {
      throw new Error('Email is required to save onboarding answers');
    }

    if (!context.moduleSlug) {
      throw new Error('Module slug is required to save onboarding answers');
    }

    if (!context.topic) {
      throw new Error('Topic is required to save onboarding answers');
    }

    const interestedSubjectAreaOptions = Object.keys(LEARNING_GOALS_DATA);
    const profileOptions = Object.keys(PROFILES);
    const learningGoalOptions = Object.keys(GOALS);

    const moduleKnowledgeOptions = TOPICS_DATA[
      context.topic
    ].placementTest.options.map((option) => option.value);

    const timeEffortOptions = Object.values(TIME_EFFORT).map(
      (timeEffort) => timeEffort.value
    );
    const onboardingSteps: OnboardQuestion[] = [
      {
        id: 'prosumer-ask-topic-subject-interest',
        type: 'single-select',
        title: 'Which are you interested in?',
        answer: interestedSubjectAreaOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.learningGoal,
          };
        }),
        options: interestedSubjectAreaOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-profile',
        type: 'single-select',
        title: 'Which best describes you?',
        answer: profileOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.profile,
          };
        }),
        options: profileOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-learning-goal',
        type: 'single-select',
        title: 'What is your learning goal?',
        answer: learningGoalOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.goal,
          };
        }),
        options: learningGoalOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-module-knowledge',
        type: 'multi-select',
        title: 'What do you know about this topic?',
        answer: moduleKnowledgeOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: context.moduleKnowledge?.includes(value) ?? false,
          };
        }),
        options: moduleKnowledgeOptions,
        isAnswered: true,
      },
      {
        id: 'prosumer-ask-time-effort',
        type: 'single-select',
        title: 'How much time can you dedicate to learning?',
        answer: timeEffortOptions.map((value, ind) => {
          return {
            index: ind,
            option: value,
            isSelected: value === context.timeEffort,
          };
        }),
        options: timeEffortOptions,
        isAnswered: true,
      },
    ];

    return await saveSoloOnboarding({
      email: context.email,
      onboardingSteps: onboardingSteps,
      partnerSignupCode: context.partnerCode,
      moduleSlug: context.moduleSlug,
    });
  },
};
