import React from 'react';
import slugify from 'slugify';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { visit } from 'unist-util-visit';
import debounce from 'lodash/debounce';
import { NAVBAR_HEIGHT } from '../components/Navbar';
import { ActivityContext } from '../contexts/activity';
import { ACTIVITY_STEP } from '../components/ActivityPage/Content';

export const ACTIVITY_LEARN_SECTIONS = {
  CONTAINER: {
    getClassName: () => 'activity-lesson-container',
    getSection: () => null,
  },
  TITLE: {
    getClassName: () => `activity-lesson-title`,
    getSection: () => 'TITLE',
  },
  HEADER: {
    getClassName: (lessonHeader: string) =>
      `activity-lesson-header-${slugify(lessonHeader, {
        lower: true,
        strict: true,
      })}`,
    getSection: (lessonHeader: string) => `HEADER-${lessonHeader}`,
  },
};

export function getActivityLessonHeaders(lesson: string) {
  const ast = fromMarkdown(lesson);
  const headers: string[] = [];
  visit(ast, 'heading', (node) => {
    if (node.depth === 2 || node.depth === 1) {
      headers.push(extractTextFromNode(node));
    }
  });
  return headers;
}

export type Node = {
  type: string;
  value?: string;
  children?: Node[];
};

export function extractTextFromNode(node: Node): string {
  if (node.type === 'text') {
    return node?.value ?? '';
  }
  if (node.children) {
    return node.children.map(extractTextFromNode).join('');
  }
  return '';
}

function getScrollYForContainer(element: Element, container: Element) {
  return (
    element.getBoundingClientRect().top + // element position
    container.scrollTop - // current scroll position
    NAVBAR_HEIGHT - // space occupied by the navbar
    50
  ); // margin offset
}

function getScrollYForWindow(element: Element) {
  return (
    element.getBoundingClientRect().top + // element position
    window.scrollY - // current scroll position
    NAVBAR_HEIGHT - // space occupied by the navbar
    50 // margin offset
  );
}

export const scrollToClass = (
  className: string,
  containerClassName?: string
) => {
  const element = document.querySelector('.' + className);

  if (!element) {
    return;
  }

  if (!containerClassName) {
    window.scrollTo({ top: getScrollYForWindow(element), behavior: 'smooth' });
  }
  const container = document.querySelector('.' + containerClassName);

  if (!container) {
    return;
  }

  container.scrollTo({
    top: getScrollYForContainer(element, container),
    behavior: 'smooth',
  });
};

function isClassInOrAboveViewport(
  className: string,
  containerClassName: string
) {
  const element = document.querySelector('.' + className);
  const container = document.querySelector('.' + containerClassName);

  if (!element || !container) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Check if the element is within or above the container's viewport

  return rect.top <= containerRect.bottom;
}

export function isClassInViewport(className: string) {
  const element = document.querySelector('.' + className);
  if (!element) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth) &&
    rect.bottom >= 0 &&
    rect.right >= 0
  );
}

export function useActivityLearnScroll(
  lessonHeaders: string[],
  containerClassName: string = ACTIVITY_LEARN_SECTIONS.CONTAINER.getClassName()
) {
  const [activeSection, setActiveSection] = React.useState('');
  const [activity] = React.useContext(ActivityContext);
  const currentStep = activity.currentStep;

  const scrollTo = React.useCallback((header?: string) => {
    return scrollToClass(
      header
        ? ACTIVITY_LEARN_SECTIONS.HEADER.getClassName(header)
        : ACTIVITY_LEARN_SECTIONS.TITLE.getClassName(),
      ACTIVITY_LEARN_SECTIONS.CONTAINER.getClassName()
    );
  }, []);

  const handleScroll = React.useCallback(() => {
    if (currentStep?.type !== ACTIVITY_STEP.LESSON) {
      setActiveSection('');
      return;
    }

    const container = document.querySelector('.' + containerClassName);
    if (!container) return;

    const containerScrollPosition = container.scrollTop;

    if (containerScrollPosition === 0) {
      setActiveSection(ACTIVITY_LEARN_SECTIONS.TITLE.getSection());
      return;
    }

    const activeHeader = [...lessonHeaders].reverse().find((header) => {
      const headerClass = ACTIVITY_LEARN_SECTIONS.HEADER.getClassName(header);

      return isClassInOrAboveViewport(headerClass, containerClassName);
    });

    if (activeHeader) {
      setActiveSection(ACTIVITY_LEARN_SECTIONS.HEADER.getSection(activeHeader));
    } else {
      setActiveSection(ACTIVITY_LEARN_SECTIONS.TITLE.getSection());
    }
  }, [setActiveSection, lessonHeaders, currentStep?.type, containerClassName]);

  const debouncedScrollHandler = debounce(handleScroll, 100);

  React.useEffect(() => {
    const container = document.querySelector('.' + containerClassName);
    if (!container) {
      return;
    }

    container.addEventListener('scroll', debouncedScrollHandler);
    debouncedScrollHandler();
    return () => {
      container.removeEventListener('scroll', debouncedScrollHandler);
    };
  }, [debouncedScrollHandler, containerClassName]);

  return { activeSection, scrollTo };
}
