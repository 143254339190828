import React from 'react';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';

day.extend(utc);

type MessageContext =
  | null
  | {
      context_type: 'why_module';
      context_content: WhyLearnContext;
    }
  | {
      context_type: 'highlight';
      context_content: HighlightContext;
    }
  | {
      context_type: 'question_hint' | 'working_question';
      context_content: QuestionContext;
    }
  | {
      context_type:
        | 'summarize_activity'
        | 'motivate_activity'
        | 'motivate_home'
        | 'suggest_module';
    };

type HighlightContext = {
  section_of_interest: string;
  highlighted_text: string;
};

type QuestionContext = {
  question_index: number;
  attempted_answer: string;
  is_correct?: boolean | null;
};

type WhyLearnContext = {
  topic_id: number;
  topic_title?: string;
};

type ChatMessage = {
  id: number;
  content: string;
  is_bot: boolean;
  is_error: boolean;
  message_context: MessageContext;
  vote: number;
};

export type AIChat = {
  id: string | null;
  messages: ChatMessage[];
  dailyMessagesCount: number;
};

type AIWorkflowType = {
  messageContext: MessageContext;
  setMessageContext: (context: MessageContext) => void;
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  clearTempMessageContext: () => void;
  hasAIAccess: boolean;
  pendingUserMessage: string | null;
  setPendingUserMessage: (message: string | null) => void;
  pendingAiMessage: string | null;
  setPendingAiMessage: (message: string | null) => void;
  chat: AIChat;
  setChat: (chat: AIChat) => void;
};

const initialContext: AIWorkflowType = {
  messageContext: null,
  setMessageContext: () => {},
  isChatOpen: false,
  setIsChatOpen: () => {},
  clearTempMessageContext: () => {},
  hasAIAccess: false,
  pendingUserMessage: null,
  setPendingUserMessage: () => {},
  pendingAiMessage: null,
  setPendingAiMessage: () => {},
  chat: {
    id: null,
    messages: [],
    dailyMessagesCount: 0,
  },
  setChat: () => {},
};

export const AIWorkflow = React.createContext<AIWorkflowType>(initialContext);

export const MESSAGE_CONTEXT_TYPES = {
  HIGHLIGHT: 'highlight',
  MOTIVATE_ACTVITY: 'motivate_activity',
  SUMMARIZE_ACTIVITY: 'summarize_activity',
  QUESTION_HINT: 'question_hint',
  WORKING_QUESTION: 'working_question',
  MOTIVATE_HOME: 'motivate_home',
  SUGGEST_MODULE: 'suggest_module',
  WHY_MODULE: 'why_module',
} as const;

// eslint-disable-next-line react/prop-types
export const AIWorkflowProvider = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  const hasAIAccess = true;

  const [chat, setChat] = React.useState<AIChat>({
    id: null,
    messages: [],
    dailyMessagesCount: 0,
  });

  const [messageContext, setMessageContext] =
    React.useState<null | MessageContext>(null);
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [pendingUserMessage, setPendingUserMessage] =
    React.useState<string | null>(null);
  const [pendingAiMessage, setPendingAiMessage] =
    React.useState<string | null>(null);

  const clearTempMessageContext = React.useCallback(() => {
    setPendingUserMessage(null);
    if (!messageContext) {
      return;
    }

    switch (messageContext.context_type) {
      case MESSAGE_CONTEXT_TYPES.QUESTION_HINT:
        setMessageContext({
          ...messageContext,
          context_type: MESSAGE_CONTEXT_TYPES.WORKING_QUESTION,
        });
        break;
      case MESSAGE_CONTEXT_TYPES.WORKING_QUESTION:
        break;
      default:
        setMessageContext(null);
        break;
    }
  }, [messageContext, setPendingUserMessage, setMessageContext]);

  const updateContext = React.useCallback((context: MessageContext) => {
    if (context) {
      if (!context.context_type) {
        return;
      }
      switch (context.context_type) {
        case MESSAGE_CONTEXT_TYPES.MOTIVATE_ACTVITY:
          setPendingUserMessage(
            'Why is this activity useful for me? Take my goals and past activity into account please!'
          );
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.SUMMARIZE_ACTIVITY:
          setPendingUserMessage(
            'Can you summarize this activity for me please, with key takeaways?'
          );
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.HIGHLIGHT:
          setPendingUserMessage(
            `What does "${context.context_content.highlighted_text}" mean in this context?`
          );
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.QUESTION_HINT:
          setPendingUserMessage('Can you give me a hint for this question?');
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.MOTIVATE_HOME:
          setPendingUserMessage(
            'How can Enki help me? Take my goals and past activity into account please!'
          );
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.SUGGEST_MODULE:
          setPendingUserMessage(
            'What topic should I learn on Enki? Take my goals and past activity into account please!'
          );
          setIsChatOpen(true);
          break;
        case MESSAGE_CONTEXT_TYPES.WHY_MODULE:
          setPendingUserMessage(
            `Why should I learn ${context.context_content.topic_title}?`
          );
          setIsChatOpen(true);
          context = {
            ...context,
            context_content: { topic_id: context.context_content.topic_id },
          };
          break;
        default:
          break;
      }
    }
    setMessageContext(context);
  }, []);

  return (
    <AIWorkflow.Provider
      value={{
        messageContext,
        setMessageContext: updateContext,
        isChatOpen,
        setIsChatOpen,
        clearTempMessageContext,
        hasAIAccess,
        pendingUserMessage,
        setPendingUserMessage,
        pendingAiMessage,
        setPendingAiMessage,
        chat,
        setChat,
      }}
    >
      {children}
    </AIWorkflow.Provider>
  );
};
