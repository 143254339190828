import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PExtraSmall, PSmall } from '../elements/Text';
import { useGetProsumerProfile } from '../../hooks/use-hydra';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useAuth from '../../hooks/use-auth';

dayjs.extend(utc);

export const Logo = styled.img.attrs({
  alt: 'Enki Logo',
  src: 'https://img.enkipro.com/8344382dc68c2c04d8846956e591e769.png',
})`
  height: 28px;
  margin-top: 0;
  padding-bottom: 0;
`;

export const NAVBAR_HEIGHT = 56;

export const NavbarWrapper = styled.div.attrs({
  id: 'navbar-main',
})`
  background: ${prop('theme.colors.navigation')};
  cursor: default;
  left: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0;
  text-align: center;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  padding: 4px 0;
`;
const RightSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  padding-left: 24px;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 24px;
  overflow-x: auto;
`;

const NavDivider = styled.div`
  height: 24px;
  width: 1px;
  background: ${(props) => props.theme.colors.divider};
  opacity: 0.6;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
  flex-grow: 1;
`;

const CrumbDivider = styled.p`
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const CrumbLink = styled(Link)`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textRegular};
  text-decoration-line: none;
  margin: 0;
  padding: 0;

  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  transition: all 300ms ease-in-out;
`;

const StaticCrumb = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.textPlaceholder};
  margin: 0;
  padding: 0;
`;

const AccountIcon = styled.i.attrs({
  className: 'fa-solid fa-user-circle',
})`
  color: ${(props) => props.theme.colors.text};
  opacity: 0.6;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  transition: all 300ms ease-in-out;
`;

const DropdownContent = styled(DropdownMenu.Content)`
  margin: 0;
  background-color: ${(props) => props.theme.colors.fieldBackground};
  color: ${prop('theme.colors.text')};
  border-radius: 8px;
  width: 240px;
  right: 0;
  z-index: 23;
`;

const DropdownLabel = styled(PExtraSmall)`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.textRegular};
  opacity: 0.6;
  padding: 10px 16px;
`;

const DropdownDivider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colors.divider};
  opacity: 0.6;
`;

const DropdownItem = styled(DropdownMenu.Item)`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  ${(props) =>
    props.$shouldHaveBottomRadius &&
    `
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  `}
  &:focus-visible {
    background-color: ${prop('theme.colors.navigation')};
    outline: 0px;
  }
  transition: all 300ms ease-in-out;
`;

const DropdownItemLabel = styled(PSmall)`
  margin: 0;
  padding: 0;
  padding: 10px 16px;
  &:focus-visible {
    outline: 0px;
  }
  color: ${(props) => props.theme.colors.textActive};
`;

const truncateText = (text) => {
  if (text.length <= 28) {
    return text;
  }
  return text.slice(0, 28) + '...';
};

const AccountDropdown = ({ email, onLogout }) => {
  const { value: profileValue } = useGetProsumerProfile();
  const { customerPortalUrl, subscriptionId } = profileValue?.learnMode || {};

  const onManageSubscription = () => {
    if (customerPortalUrl) {
      window.open(customerPortalUrl, '_blank');
    }
  };
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <AccountIcon />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownContent>
          <DropdownLabel>{truncateText(email)}</DropdownLabel>
          <DropdownDivider />
          {profileValue && subscriptionId && (
            <DropdownItem onClick={onManageSubscription}>
              <DropdownItemLabel>Manage Subscription</DropdownItemLabel>
            </DropdownItem>
          )}
          <DropdownDivider />
          <DropdownItem onClick={onLogout} $shouldHaveBottomRadius>
            <DropdownItemLabel>Logout</DropdownItemLabel>
          </DropdownItem>
        </DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default function Navbar({
  isPreview = false,
  breadCrumbs = [],
  className = '',
}) {
  const { user, logout } = useAuth();
  const onLogout = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <NavbarWrapper className={className}>
      <div className="inner">
        <Nav>
          <LeftSection>
            <Logo />
            <NavDivider />

            <Breadcrumbs>
              {breadCrumbs.map((crumb, index) => (
                <React.Fragment key={crumb.label}>
                  {crumb.url ? (
                    <CrumbLink to={crumb.url}>{crumb.label}</CrumbLink>
                  ) : (
                    <StaticCrumb>{crumb.label}</StaticCrumb>
                  )}
                  {index !== breadCrumbs.length - 1 && (
                    <CrumbDivider>/</CrumbDivider>
                  )}
                </React.Fragment>
              ))}
              {isPreview && (
                <>
                  <CrumbDivider>/</CrumbDivider>
                  <StaticCrumb>Preview</StaticCrumb>
                </>
              )}
            </Breadcrumbs>
          </LeftSection>
          <RightSection>
            <NavDivider />
            <AccountDropdown
              email={user?.email ?? 'aaaaaa'}
              onLogout={onLogout}
            />
          </RightSection>
        </Nav>
      </div>
    </NavbarWrapper>
  );
}
