import { ActivityContextQuestionAnswer } from '..';
import { LatestSubmissionPayload, QuestionPayload } from '../../../hydra';

export default function isSubmissionDifferent(
  submission: {
    feedback: string;
    currentSubmissionStatus: string;
    difficultyRating: number | null;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
  },
  latestSavedSubmission: LatestSubmissionPayload,
  questions: Array<QuestionPayload>
) {
  const answerStates = latestSavedSubmission?.answer_states;
  return (
    submission.difficultyRating !== latestSavedSubmission?.difficulty_rating ||
    submission.feedback !== latestSavedSubmission?.feedback ||
    !!questions.find((question) => {
      const currentAnswer =
        question.type === 'PLAYGROUND'
          ? submission.questionSlugToAnswer[question.slug]?.query ?? ''
          : submission.questionSlugToAnswer[question.slug]?.value ?? '';
      const submittedAnswer = answerStates
        ? answerStates[question.slug].value
        : '';

      return currentAnswer !== submittedAnswer;
    })
  );
}
