import styled from 'styled-components';
import { PExtraSmall, PSmall } from './Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.bgActive};
  padding: 3px;
  justify-content: space-evenly;
  gap: 3px;
  margin: 10px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 9px;
  border-radius: 5px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.bgDefault : 'transparent'};

  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  transition: all 0.3s ease-in-out;
`;

const TabHeader = styled(PExtraSmall)<{ isActive: boolean }>`
  margin-top: 0;
  padding-bottom: 0;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textActive : theme.colors.textRegular};
  transition: all 0.3s ease-in-out;
`;

export default function Tabs({
  options,
  selectedOptionIndex,
  onClick,
}: {
  options: string[];
  selectedOptionIndex: number;
  onClick: (index: number) => void;
}) {
  return (
    <Wrapper>
      {options.map((option, index) => (
        <Tab
          isActive={index === selectedOptionIndex}
          onClick={() => onClick(index)}
          key={option}
        >
          <TabHeader isActive={index === selectedOptionIndex}>
            {option}
          </TabHeader>
        </Tab>
      ))}
    </Wrapper>
  );
}

const LightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const LightTab = styled.div<{ isActive: boolean }>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  background: transparent;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  transition: all 0.3s ease-in-out;
`;

const LightTabHeader = styled(PSmall)<{ isActive: boolean }>`
  margin-top: 0;
  padding-bottom: 4px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textActive : theme.colors.textRegular};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  transition: all 0.3s ease-in-out;

  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 2px solid ${theme.colors.primary};
  `}
`;

export function LightTabs({
  options,
  selectedOptionIndex,
  onClick,
}: {
  options: string[];
  selectedOptionIndex: number;
  onClick: (index: number) => void;
}) {
  return (
    <LightWrapper>
      {options.map((option, index) => (
        <LightTab
          key={`light-tab-${options}-${index}`}
          isActive={index === selectedOptionIndex}
          onClick={() => onClick(index)}
        >
          <LightTabHeader isActive={index === selectedOptionIndex}>
            {option}
          </LightTabHeader>
        </LightTab>
      ))}
    </LightWrapper>
  );
}
