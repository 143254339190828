import { useContext, useState } from 'react';
import { EVENTS, STATES, STATES_TO_ROUTES } from '../state-machine';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import styled, { useTheme } from 'styled-components';

import SoloOnboardingContext from '../context';
import { useActor } from '@xstate/react';
import Loader from '../../Loader';
import {
  CompletedStepCircle,
  CTAButton,
  CurrentStepCircle,
  FontAwesomeIcon,
  Header,
  QuoteWithStars,
} from './common';
import { P, H5, PSmall } from '../../elements/Text';
import * as CrashReporter from '../../../crash-reporter';
import * as Analytics from '../../../analytics';
import { toast } from 'react-toastify';

let stripePromise: Promise<Stripe | null>;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
    );
  }
  return stripePromise;
};

const BillingPlanCard = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  border-color: ${({ theme }) => theme.colors.inputBackground};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 12px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PlanNameLabel = styled(P)`
  color: ${({ theme }) => theme.colors.textActive};
  margin: 0;
  padding: 0;
`;
const PlanSublabel = styled(PSmall)`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.textRegular};
`;
const FreeTrialLabel = styled(P)`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
`;

const Column = styled.div<{ alignment?: 'left' | 'right' }>`
  text-align: ${(props) => props.alignment};
`;

const Switch = styled.div<{ toggle: boolean }>`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;
  border-radius: 10px;
  transition: 0.4s;
  margin-right: 10px;
`;

const Slider = styled.span<{ toggle: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ toggle, theme }) =>
    toggle ? theme.colors.primary : theme.colors.fieldBackground};
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';

    position: absolute;
    left: ${({ toggle }) => (toggle ? `20.4px` : `1.3px`)};
    bottom: 1.3px;

    width: 13px;
    height: 13px;
    border-radius: 100%;

    background-color: ${({ toggle, theme }) =>
      toggle ? theme.colors.fieldBackground : theme.colors.primary};

    transition: 0.3s;
  }
`;

const AnnualizeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const YourPlanLabel = styled(P)`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: white;
`;

const AnnualizeSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderContainer = styled.div`
  min-height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  animation: fadeIn 1s ease-in-out forwards;
  text-align: center;
  padding-top: 72px;

  @media only screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  text-align: left;
  padding-bottom: 72px;
  margin-top: 40px;
`;

const PaymentFormContainer = styled.div`
  width: 100%;
  max-width: 680px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const MoreExplainerContainer = styled.div`
  width: 320px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SectionHeader = styled(H5)`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.textActive};
  font-weight: bold;
`;

export default function Purchase() {
  const onboardingService = useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_UPSELL_PAYMENT,
    state.context
  );

  const toggleBillingPreference = () => {
    send({
      type: EVENTS.SET_BILLING_PREFERENCE,
      isMonthlySubscription: !state.context.isMonthlySubscription,
    });
  };

  const onLoadError = (err: Error) => {
    CrashReporter.reportError(err);
    // delaying for animation
    setTimeout(() => {
      send(EVENTS.PAYMENT_ERROR);
    }, 500);
    return null;
  };

  return (
    <PaymentPage
      email={state.context?.email ?? ''}
      returnUrl={
        `${window.location.origin}/join/` +
        STATES_TO_ROUTES[
          STATES.PAYMENT_CALLBACK as keyof typeof STATES_TO_ROUTES
        ]
      }
      isMonthlySubscription={state.context.isMonthlySubscription}
      toggleBillingPreference={toggleBillingPreference}
      paymentIntentClientSecret={state.context.paymentIntentClientSecret}
      discountPercentage={state.context.discountPercentage}
      onLoadError={onLoadError}
      shouldShowTrial
    />
  );
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export function PaymentPage({
  email,
  returnUrl,
  isMonthlySubscription,
  toggleBillingPreference,
  paymentIntentClientSecret,
  shouldShowTrial,
  discountPercentage,
  onLoadError,
}: {
  email: string;
  returnUrl: string;
  isMonthlySubscription: boolean;
  toggleBillingPreference: () => void;
  paymentIntentClientSecret: string | null | undefined;
  shouldShowTrial: boolean;
  discountPercentage: number | null | undefined;
  onLoadError: (error: Error) => null;
}) {
  const theme = useTheme();
  const getStripePromise = getStripe().catch(onLoadError);

  const getDiscountedPrice = (price: number) => {
    const discountedPrice = discountPercentage
      ? (price * (100 - discountPercentage)) / 100
      : price;

    if (discountedPrice % 1 === 0) {
      return `$${discountedPrice}`;
    }
    return Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
    }).format(discountedPrice);
  };

  return (
    <Wrapper>
      <Header>
        {shouldShowTrial ? 'Start your 7-day free trial' : 'Purchase Enki'}
      </Header>
      <FlexContainer>
        <PaymentFormContainer>
          <Section>
            <AnnualizeHeader>
              <SectionHeader>Your Plan</SectionHeader>
              <AnnualizeSwitchContainer>
                <Switch toggle={!isMonthlySubscription}>
                  <Slider
                    toggle={!isMonthlySubscription}
                    onClick={
                      paymentIntentClientSecret
                        ? toggleBillingPreference
                        : () => {}
                    }
                  />
                </Switch>
                <PlanSublabel>Billed yearly (Save 60%)</PlanSublabel>
              </AnnualizeSwitchContainer>
            </AnnualizeHeader>
            <BillingPlanCard>
              <Column>
                <PlanNameLabel>Enki Desktop</PlanNameLabel>

                <PlanSublabel>
                  Billed {!isMonthlySubscription ? 'yearly' : 'monthly'}
                </PlanSublabel>
              </Column>
              <Column alignment="right">
                <FreeTrialLabel>
                  {shouldShowTrial ? (
                    '$0 for 7 day free trial'
                  ) : isMonthlySubscription ? (
                    <>
                      {discountPercentage && <s>{`$25`}</s>}
                      <span>{` ${getDiscountedPrice(25)} today`}</span>
                    </>
                  ) : (
                    <>
                      {discountPercentage && <s>{`$120`}</s>}
                      <span>{` ${getDiscountedPrice(120)} today`}</span>
                    </>
                  )}
                </FreeTrialLabel>
                <PlanSublabel>
                  {`Then `}
                  {isMonthlySubscription ? (
                    <>
                      {discountPercentage && <s>{`$25`}</s>}
                      <span>{` ${getDiscountedPrice(25)} per month`}</span>
                    </>
                  ) : (
                    <>
                      {discountPercentage && <s>{`$10`}</s>}
                      <span>{` ${getDiscountedPrice(
                        10
                      )}/mo x 12 months = ${getDiscountedPrice(
                        120
                      )}/year`}</span>
                    </>
                  )}
                </PlanSublabel>
              </Column>
            </BillingPlanCard>
          </Section>

          {paymentIntentClientSecret ? (
            <Section style={{ gap: 12 }}>
              <Elements
                key={paymentIntentClientSecret}
                stripe={getStripePromise}
                options={{
                  clientSecret: paymentIntentClientSecret,
                  appearance: {
                    theme: 'flat',
                    variables: {
                      fontFamily: ' "Inter", sans-serif',
                      fontSizeBase: '20px',
                      spacingUnit: '4px',
                      fontLineHeight: 'normal',
                      borderRadius: '8px',
                      colorBackground: theme.colors.inputBackground,
                      colorPrimary: theme.colors.primary,
                      colorDanger: theme.colors.textDanger,
                      colorText: theme.colors.inputTextActive,
                      gridColumnSpacing: '12px',
                      gridRowSpacing: '12px',
                    },
                    rules: {
                      '.Error': {
                        fontSize: '15px',
                      },
                    },
                  },
                }}
              >
                <BillingForm
                  email={email}
                  returnUrl={returnUrl}
                  shouldShowTrial={shouldShowTrial}
                  onLoadError={onLoadError}
                />
              </Elements>
            </Section>
          ) : (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </PaymentFormContainer>
        <MoreExplainerContainer>
          <TrialExplainer shouldShowTrial={shouldShowTrial} />
          <QuoteWithStars
            text={'I learnt more from Enki than my $20,000 diploma.'}
            author="Zach B."
            profileImage="https://img.enkipro.com/3707e9f57f5323803f7f70a44fe4a28f.png"
          />
        </MoreExplainerContainer>
      </FlexContainer>
    </Wrapper>
  );
}

const CancellationLabel = styled.p`
  font-size: 0.8rem;
  line-height: 1.5;
`;

const ShieldIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
`;

const SafetyGuarenteeLabel = styled.p`
  font-size: 20px;
  color: white;
`;

function BillingForm({
  email,
  returnUrl,
  shouldShowTrial,
  onLoadError,
}: {
  email: string;
  returnUrl: string;
  shouldShowTrial: boolean;
  onLoadError: (error: Error) => null;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [loadingPaymentCompletion, setLoadingPaymentCompletion] =
    useState(false);

  const onBuy = async () => {
    setLoadingPaymentCompletion(true);
    if (!stripe || !elements) {
      setLoadingPaymentCompletion(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    if (error?.code === 'card_declined') {
      toast.error(
        'Your card was declined. Please try another payment method.',
        {
          autoClose: false,
        }
      );
    }

    setLoadingPaymentCompletion(false);
  };

  return (
    <Section>
      <SectionHeader>Your Payment Information</SectionHeader>
      <BillingPlanCard>{email}</BillingPlanCard>

      <PaymentElement
        onLoadError={({ error }) => {
          onLoadError(new Error(error.message));
        }}
      />

      <SafetyGuarenteeLabel>
        <ShieldIcon className="fa-solid fa-shield-check" /> Guaranteed safe
        checkout
      </SafetyGuarenteeLabel>
      <CTAButton
        onClick={onBuy}
        isDisabled={!stripe || !elements}
        label={shouldShowTrial ? 'Start 7 Day Trial' : 'Purchase Enki'}
        style={{ width: '100%' }}
        isLoading={loadingPaymentCompletion}
      />
      <CancellationLabel>
        Cancel anytime on your account page or by emailing/chatting support.
        Your {shouldShowTrial && 'trial and'} subscription will automatically
        renew unless cancelled. Enki may place a temporary authorization hold of
        $1 on your account to verify your payment information. The hold will be
        removed within 5-7 business days.
      </CancellationLabel>
    </Section>
  );
}

const TrialExplanationContainer = styled.div`
  border-radius: 16px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.bgDefault};
  display: flex;
  flex-direction: column;
`;

const TrialStepHeader = styled.div`
  padding: 0;
  display: flex;
  gap: 16px;
  align-items: center;
  line-height: normal;
`;
const TrialStepExplainer = styled.p<{ shouldShowBorder?: boolean }>`
  margin: 0;
  padding-left: 30px;
  margin-left: 13px;
  padding-bottom: 16px;
  padding-top: 10px;
  border-left: ${({ shouldShowBorder }) => (shouldShowBorder ? '2' : '0')}px
    solid ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  line-height: normal;
`;

function TrialExplainer({ shouldShowTrial }: { shouldShowTrial: boolean }) {
  return shouldShowTrial ? (
    <TrialExplanationContainer>
      <TrialStepHeader>
        <CompletedStepCircle style={{ height: 28, width: 28 }}>
          <FontAwesomeIcon className={'fa-duotone fa-check'} />
        </CompletedStepCircle>
        <YourPlanLabel>Today</YourPlanLabel>
      </TrialStepHeader>
      <TrialStepExplainer shouldShowBorder>
        Your trial begins free for 7 days
      </TrialStepExplainer>
      <TrialStepHeader>
        <CurrentStepCircle style={{ height: 28, width: 28 }} />
        <YourPlanLabel>Day 5</YourPlanLabel>
      </TrialStepHeader>
      <TrialStepExplainer shouldShowBorder>
        We will send you an email reminder that your trial is about to expire
      </TrialStepExplainer>
      <TrialStepHeader>
        <CurrentStepCircle style={{ height: 28, width: 28 }} />
        <YourPlanLabel>Day 7</YourPlanLabel>
      </TrialStepHeader>
      <TrialStepExplainer>
        Your subscription starts when your trial ends. You can cancel your
        subscription anytime on your account page, or by emailing support
      </TrialStepExplainer>
    </TrialExplanationContainer>
  ) : (
    <TrialExplanationContainer>
      <TrialStepHeader>
        <CompletedStepCircle style={{ height: 28, width: 28 }}>
          <FontAwesomeIcon className={'fa-duotone fa-check'} />
        </CompletedStepCircle>
        <YourPlanLabel>Today</YourPlanLabel>
      </TrialStepHeader>
      <TrialStepExplainer>
        Your subscription starts today. You can cancel your subscription anytime
        on your account page or by email
      </TrialStepExplainer>
    </TrialExplanationContainer>
  );
}
