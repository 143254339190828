import React from 'react';
import PythonPlayground from '../components/PythonPlayground';
import styled from 'styled-components';

const PlaygroundContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

export default function EmbeddablePythonPlayground() {
  const urlParams = new URLSearchParams(window.location.search);
  const isReadOnly = urlParams.get('readonly') === 'true';
  const startingCode = urlParams.get('startingcode');
  const [code, setCode] = React.useState(startingCode || '');

  const iframeHeight = window.innerHeight;
  return (
    <PlaygroundContainer height={iframeHeight}>
      <PythonPlayground
        code={code}
        onCodeChange={(code) => {
          setCode(code);
        }}
        onResult={() => {}}
        onResetCode={() => {
          setCode('');
        }}
        isReadOnly={isReadOnly}
      />
    </PlaygroundContainer>
  );
}
