import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import { Link } from 'react-router-dom';

const ActivityCardWrapper = styled(Link)`
  text-decoration: none;
  padding: 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 100px;

  @media screen and (min-width: 1280px) and (max-width: 1365px) {
    height: 120px;
  }

  ${({ $isFirstActivityInMission }) =>
    $isFirstActivityInMission &&
    `
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  `}

  ${({ $isLastActivityInMission }) =>
    $isLastActivityInMission &&
    `
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `}

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  opacity: 0.8;
  background-color: ${({ theme }) => theme.colors.bgDefault};

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
    transition: background-color 0s;
    transition: opacity 0s;
  }

  ${({ $isStretch, theme }) =>
    $isStretch &&
    css`
      padding: 11px;
      border-style: dashed;
      border-width: 1px;
      border-color: ${theme.colors.bgDefaultSecondary};
      background-color: ${({ theme }) => theme.colors.bgDefaultSecondary};
      opacity: 0.7;

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 1;
        transition: opacity 0s;
        transition: background-color 0s;
        transition: border-color 0s;
      }
    `}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      opacity: 1;
      background-color: #fca92a1a;

      &:hover {
        opacity: 1;
        background-color: #fca92a26 !important;
      }

      &:active {
        opacity: 1;
        background-color: #fca92a33 !important;
        transition: opacity 0s;
        transition: background-color 0s;
        transition: border-color 0s;
      }
    `}
`;

const ActivityCardColumn = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

// same as .small + bold styles
const ActivityTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${prop('theme.colors.text')};

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: #fca92a;
    `}

  padding-bottom: 0px;
  margin-top: 0px !important;
`;

// same as .uppercase-label styles
const ActivityCaption = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin-top: 0px !important;
  color: ${prop('theme.colors.text')};
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: #fca92a;
    `}
  opacity: 0.6;
`;

export default function ActivityCard({
  activity,
  style,
  isFirstActivityInMission,
  isLastActivityInMission,
  isHighlightingOutstanding,
  href,
}) {
  const isOutstanding = activity.isSent && !activity.isSubmitted;
  const shouldDim = !isHighlightingOutstanding || !isOutstanding;
  const isHighlighted = !shouldDim && activity.isCore;

  const typeLabel = activity.isCore ? 'CORE' : 'STRETCH';

  return (
    <ActivityCardWrapper
      to={href}
      style={style}
      className={`grid-activity-item ${shouldDim ? 'dim-content' : ''} ${
        isHighlighted ? 'highlighted-content' : ''
      }`}
      $isFirstActivityInMission={isFirstActivityInMission}
      $isLastActivityInMission={isLastActivityInMission}
      $isStretch={!activity.isCore}
      $isHighlighted={isHighlighted}
    >
      <ActivityCardColumn
        style={{
          margin: '5px 4px 4px 8px',
        }}
      >
        <ActivityTitle isHighlighted={isHighlighted}>
          {activity.title}
        </ActivityTitle>
        <ActivityCaption isHighlighted={isHighlighted}>
          {typeLabel}
        </ActivityCaption>
      </ActivityCardColumn>
      <ActivityCardColumn
        style={{
          marginLeft: '16px',
        }}
      >
        <ActivityIcon activity={activity} />
      </ActivityCardColumn>
    </ActivityCardWrapper>
  );
}

const ActivityIconWrapper = styled.i`
  color: ${({ color = prop('theme.colors.primary') }) => color};
  font-size: 16px;
`;

function ActivityIcon({ activity }) {
  if (activity.instructorReview || activity.instructorScore) {
    return (
      <ActivityIconWrapper
        className="fa-solid fa-message-check"
        title="Reviewed"
      />
    );
  }
  if (activity.isSubmitted) {
    return (
      <ActivityIconWrapper
        className="fa-solid fa-circle-check"
        title="Submitted"
      />
    );
  }
  return (
    <ActivityIconWrapper
      className="fa-solid fa-circle-check"
      color="transparent"
      title=""
    />
  );
}
