import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { CodeSmall, PExtraSmall } from '../elements/Text';

const SQLPlaygroundSchemaTable = styled.table`
  background-color: transparent;
  margin-top: 8px;

  th {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 145% */
    letter-spacing: 0.025em;
    color: ${(props) => props.theme.colors.textRegular};
    padding: 8px;
  }

  td {
    color: ${(props) => props.theme.colors.textRegular};
    font-family: Roboto Mono;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
  }

  thead {
    border-bottom: 1px solid ${(props) => props.theme.colors.divider};
  }

  overflow: auto;
`;
const SchemaCaption = styled(PExtraSmall)`
  margin-top: 16px;
  font-weight: 700;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.textRegular};
  padding-left: 8px;
  align-self: center;
`;
const CodePlaceholder = styled(CodeSmall)`
  color: ${({ theme }) => theme.colors.textRegular};
`;

const Wrapper = styled.div`
  padding: 4px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export default function SQLSchemas({
  schemas,
}: {
  schemas: Array<{
    name: string;
    schema: {
      columns: string[];
      values: string[][];
    };
  }>;
}) {
  const isDBEmpty = isEmpty(schemas);

  if (isDBEmpty) {
    return (
      <Wrapper>
        <CodePlaceholder>No tables in this database</CodePlaceholder>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {schemas.map(({ schema, name }) => (
        <div key={name}>
          <SchemaCaption>{name}</SchemaCaption>
          <SQLPlaygroundSchemaTable>
            <thead>
              <tr>
                <th>Column</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {schema.values.map((val, i) => (
                <tr key={i}>
                  <td>{val[1]}</td>
                  <td>{val[2]}</td>
                </tr>
              ))}
            </tbody>
          </SQLPlaygroundSchemaTable>
        </div>
      ))}
    </Wrapper>
  );
}
