import React from 'react';
import styled from 'styled-components';
import AceEditor from 'react-ace';

import Sqlite from './sqliteMode';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-one_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import Loader from '../Loader';
import flatten from 'lodash.flatten';
import { SQLSchemasType } from '../../contexts/activity/helpers/is-sql-playground-question-correct';
import Alert from '../Alert';

const Wrapper = styled.div<{ shouldCentre?: boolean }>`
  height: 40vh;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 2px;

  ${({ shouldCentre }) =>
    shouldCentre &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
  `}
`;

export const StyledAceEditor = styled(AceEditor)`
  flex-grow: 1;
  z-index: 1;
  font-family: 'Roboto Mono';
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  .ace_cursor {
    color: #70ffbc;
  }
  background-color: transparent;

  .ace-tm,
  .ace_gutter {
    background-color: transparent;
  }

  .ace_placeholder {
    padding-left: 0 !important;
    font-style: normal !important;
  }
`;

const uppercaseKeywordCompleter = {
  // @ts-expect-error - ace editor completer is not typed properly
  getCompletions(editor, session, pos, prefix, callback) {
    if (session.$mode.completer) {
      return session.$mode.completer.getCompletions(
        editor,
        session,
        pos,
        prefix,
        callback
      );
    }

    const state = editor.session.getState(pos.row);
    const keywordCompletions = session.$mode
      .getCompletions(state, session, pos, prefix)
      // @ts-expect-error - ace editor completer is not typed properly
      .map((completion) => ({
        ...completion,
        value: completion.value.toUpperCase(),
      }));

    callback(null, keywordCompletions);
  },
};

const mapKeywordCompleter = (name: string) => ({
  name,
  value: name,
  caption: name,
  meta: name,
});

const getTableAndColumnsKeywordCompleter = (schemas: SQLSchemasType) => ({
  // @ts-expect-error - ace editor completer is not typed properly
  getCompletions(editor, session, pos, prefix, callback) {
    const tableNames = schemas.map(({ name }) => name);
    const columnNames = flatten(
      schemas.map(({ schema }) => schema.values.map((value) => value[1]))
    );
    callback(null, [...tableNames, ...columnNames].map(mapKeywordCompleter));
  },
});

export default function SQLCodeEditor({
  id = 'sql-editor',
  query = '',
  onQueryChange,
  isLoading,
  schemas,
  error,
  isReadOnly = false,
}: {
  id?: string;
  query: string;
  onQueryChange: (query: string) => void;
  isLoading?: boolean;
  schemas: SQLSchemasType;
  error?: Error;
  isReadOnly?: boolean;
}) {
  const aceEditorRef = React.createRef<AceEditor>();

  React.useEffect(() => {
    const customMode = new Sqlite();

    if (aceEditorRef.current) {
      // @ts-expect-error - ace editor ref is not typed properly
      aceEditorRef.current.editor.getSession().setMode(customMode);
    }
  }, [aceEditorRef]);

  React.useEffect(() => {
    if (!aceEditorRef.current) {
      return;
    }
    aceEditorRef.current.editor.completers = [
      uppercaseKeywordCompleter,
      getTableAndColumnsKeywordCompleter(schemas),
    ];
  }, [id, schemas, aceEditorRef]);

  if (isLoading) {
    return (
      <Wrapper shouldCentre>
        <Loader />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper shouldCentre>
        <Alert type="error" text={error.message} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StyledAceEditor
        name={id}
        ref={aceEditorRef}
        mode="text"
        // @ts-expect-error - ace editor is not typed properly
        theme="one_dark"
        placeholder="Write your SQL query here"
        focus={true}
        value={query}
        highlightActiveLine={false}
        onChange={(val) => {
          if (val !== query) {
            onQueryChange(val);
          }
        }}
        tabSize={2}
        width={'100%'}
        height={'100%'}
        fontSize={15}
        showPrintMargin={false}
        editorProps={{ $blockScrolling: Infinity }}
        enableBasicAutocompletion
        enableLiveAutocompletion
        onSelectionChange={() => {
          if (aceEditorRef?.current) {
            document.dispatchEvent(
              new CustomEvent('editorselectionchange', {
                detail: {
                  selection: aceEditorRef.current.editor.getSelectedText(),
                  section: aceEditorRef.current.editor.getValue(),
                },
              })
            );
          }
        }}
        readOnly={isReadOnly}
      />
    </Wrapper>
  );
}
